import React from 'react';
import {MAX_NAVBAR_HEIGHT, MIN_MAIN_CONTENT_HEIGHT, MIN_NAVBAR_HEIGHT} from "../../constants";
import useWindowDimensions from "../../utils/hooks/useWindowDimensions";

const Index = ({ children, isFirst=false }) => {
    const windowDimensions = useWindowDimensions()

    return (
        <section style={{
            paddingTop: windowDimensions.height < MIN_MAIN_CONTENT_HEIGHT ? 0 : isFirst ? MAX_NAVBAR_HEIGHT : MIN_NAVBAR_HEIGHT,
            width: "100%",
            height: "100vh",
            minHeight: MIN_MAIN_CONTENT_HEIGHT,
            display: "flex",
            position: "relative",
            scrollSnapAlign: "start",
            perspective: 500,
        }}>
            <div
                style={{
                    width: "100%",
                    height: "100%",
                    position: "relative",
                }}>
                <div style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0
                }}
                >
                    {children}
                </div>
            </div>
        </section>
    );
};

export default Index;