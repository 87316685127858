import React, {useEffect, useRef} from 'react';
import {Image, Stack} from "react-bootstrap";
import {motion, useInView} from "framer-motion";
import {MAX_MAIN_CONTENT_WIDTH, SLIDE_FROM_BELOW, SLIDE_FROM_RIGHT} from "../../constants";
import BgImage from "../../resources/bg2.jpeg";
import Profile from "../../resources/pfp.jpeg"

const Index = () => {
    const ID = "about-us";
    const ref = useRef(null);
    const inView = useInView(ref);

    useEffect(() => {
        if (inView) {
            window.history.pushState('', '', `#${ID}`);
        }
    }, [inView]);

    return (
        <Stack className={"w-100 h-100 justify-content-center align-items-center"}
               style={{
                   backgroundImage: `url(${BgImage})`,
                   backgroundSize: "cover",
                   backgroundPosition: "center"
               }}
               id={ID}>
            <motion.div
                initial="offscreen"
                whileInView="onscreen"
                className={"shadow-lg  rounded-3 d-flex flex-column justify-content-center"}
                viewport={{ once: true, amount: 0.5}}
                style={{overflow: "hidden",
                    backdropFilter: "blur(20px)",
                    minHeight: "50vh",
                    maxWidth: "95vw",
                    backgroundColor: "rgba(255, 255, 255, 0.81)"}}>
                <div className={"px-1 px-sm-2 px-md-3 px-lg-4 px-2 container container-fluid"}
                     style={{ maxWidth: MAX_MAIN_CONTENT_WIDTH}}>
                    <hr className={"shadow"}/>
                </div>
                <motion.div variants={SLIDE_FROM_RIGHT}
                            className={"mt-sm-3 px-2 px-sm-3 px-md-4 px-lg-5 px-2 container container-fluid"}
                            style={{ maxWidth: MAX_MAIN_CONTENT_WIDTH}}>
                    <h1 ref={ref}>About Us</h1>
                    <ul>
                        <li>Vardaan center for special children</li>
                        <li>U/O Khushi Ishi charitable Trust</li>
                        <li>Center started in February 2021.</li>
                        <li>Located In Omaxe Heights, Faridabad.</li>
                        <li>Aims to provide comprehensive special education to children with neurological disabilities.</li>
                    </ul>
                </motion.div>
                <div className={"mt-sm-2 px-1 px-sm-2 px-md-3 px-lg-4 px-2 container container-fluid"}
                     style={{ maxWidth: MAX_MAIN_CONTENT_WIDTH}}>
                    <hr className={"shadow"}/>
                </div>
                <motion.div variants={SLIDE_FROM_BELOW}
                            className={"mt-sm-3 px-2 px-sm-3 px-md-4 px-lg-5 px-2 container container-fluid"}
                            style={{ maxWidth: MAX_MAIN_CONTENT_WIDTH}}>
                    <h1>President</h1>
                    <Stack direction={"horizontal"} className={"w-100 align-items-start mt-sm-3"}>
                        <Image roundedCircle
                               className={"shadow-sm p-1 border border-success"}
                               height={128} width={128}
                               src={Profile}/>
                        <Stack className={"ms-4"}>
                            <h4 >Shikha Tyagi</h4>
                            <p className={"ms-1"}>MCA <br/> B.Ed <br/> Special Educator</p>
                        </Stack>
                    </Stack>
                </motion.div>
                <div className={"mt-sm-3 px-1 px-sm-2 px-md-3 px-lg-4 px-2 container container-fluid"}
                     style={{ maxWidth: MAX_MAIN_CONTENT_WIDTH}}>
                    <hr className={"shadow"}/>
                </div>
            </motion.div>
        </Stack>

    );
};

export default Index;