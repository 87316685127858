import React, {useEffect} from 'react';
import {Carousel, Stack} from "react-bootstrap";
import BgImage from '../../resources/bg.jpeg'
import {motion, useInView} from "framer-motion";
import { MAX_CAROUSEL_IMAGE_HEIGHT, MIN_NAVBAR_HEIGHT,
} from "../../constants";
import Video1 from "../../resources/ActivitiesVideos/1.1.mp4"
import Video2 from "../../resources/ActivitiesVideos/2.1.mp4"
import Video3 from "../../resources/ActivitiesVideos/3.1.mp4"
import Video4 from "../../resources/ActivitiesVideos/4.1.mp4"
import Video5 from "../../resources/ActivitiesVideos/5.1.mp4"
import Video6 from "../../resources/ActivitiesVideos/6.1.mp4"
import Video7 from "../../resources/ActivitiesVideos/7.1.mp4"


const Index = () => {
    const ID = "activities";
    const ref = React.useRef(null);
    const inView = useInView(ref);

    useEffect(() => {
        if (inView) {
            window.history.pushState('', '', `#${ID}`);
        }
    }, [inView]);

    const videos = [Video1, Video2, Video3, Video4, Video5, Video6, Video7]
    const slideTitles = ["Activities", "Activities", "Focus On Individual Child", "Focus On Individual Child", "Occupational Therapy", "Occupational Therapy", "Contact Us"]
    const slideTexts = [
        "Our activities help in the holistic development of children with autism and other learning disabilities.",
        "Our activities help in the holistic development of children with autism and other learning disabilities.",
        "We provide special individualized program for each child.",
        "We provide special individualized program for each child.",
        "We also provide occupational therapy for children with ADHD.",
        "We also provide occupational therapy for children with ADHD.",
        <p>Click on <a href={"#contact-us"}>Contact Us</a> for consultation.</p>,
    ]

    return (
        <Stack className={"w-100 h-100 justify-content-center align-items-center"}
               style={{
                   backgroundImage: `url(${BgImage})`,
                   backgroundSize: "cover",
                   backgroundPosition: "center"
                }}
               id={ID}>
            <motion.div
                initial="offscreen"
                whileInView="onscreen"
                className={"shadow-lg d-flex flex-column justify-content-center w-100 h-100 bg-filter"}
                viewport={{ once: true, amount: 0.5}}
                style={{overflow: "hidden",
                    backgroundColor: "rgba(255, 255, 255, 0.81)"}}>
                <Carousel interval={15000} slide variant={"dark"} className={"h-100 d-flex w-100"} pause={false}>
                    {
                        videos.map((video, index) => (
                            <Carousel.Item key={index}
                                           style={{height: `calc(100vh - ${MIN_NAVBAR_HEIGHT}px)`,
                                               backgroundSize: "cover",
                                               backgroundPosition: "center",
                                           }}>
                                <Stack className={"w-100 d-flex align-items-center justify-content-center h-100"}>
                                     <video
                                         playsInline autoPlay muted loop controls
                                        className={"shadow-lg rounded-1"}
                                        style={{
                                            maxWidth: "100%",
                                            maxHeight: MAX_CAROUSEL_IMAGE_HEIGHT,
                                            objectFit: "contain",
                                            objectPosition: "center",
                                        }}>
                                         <source src={video} type={"video/mp4"}/>
                                     </video>
                                    <Carousel.Caption>
                                        <h3>{slideTitles[index]}</h3>
                                        {slideTexts[index]}
                                    </Carousel.Caption>
                                </Stack>
                            </Carousel.Item>
                        ))
                    }
                </Carousel>
                <div ref={ref} style={{position: "absolute"}}/>
            </motion.div>
        </Stack>

    );
};

export default Index;