import React, {useEffect, useRef} from 'react';
import {Button, Stack} from "react-bootstrap";
import {motion, useInView} from "framer-motion";
import {MAX_MAIN_CONTENT_WIDTH, SLIDE_FROM_BELOW, SLIDE_FROM_RIGHT} from "../../constants";
import {Envelope, GlobeCentralSouthAsia, Telephone, Whatsapp} from "react-bootstrap-icons";
import useWindowDimensions from "../../utils/hooks/useWindowDimensions";

const Index = () => {
    const ID = "contact-us";
    const ref = useRef(null);
    const inView = useInView(ref);
    const dimensions = useWindowDimensions()

    useEffect(() => {
        if (inView) {
            window.history.pushState('', '', `#${ID}`);
        }
    }, [inView]);

    return (
        <Stack className={"w-100 h-100 justify-content-center align-items-center"}
               style={{
                   background: "linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab)",
                   backgroundSize: "400% 400%",
                   animation: "gradient 15s ease infinite",
               }}
               id={ID}>
            <motion.div
                initial="offscreen"
                whileInView="onscreen"
                className={"shadow-lg  rounded-4 d-flex flex-column justify-content-center"}
                viewport={{ once: true, amount: 0.5}}
                style={{overflow: "hidden",
                    backdropFilter: "blur(20px)",
                    minHeight: "50vh",
                    width: "95vw",
                    maxWidth: MAX_MAIN_CONTENT_WIDTH,
                    backgroundColor: "rgba(255, 255, 255, 0.81)"}}
            >
                <div className={"px-1 px-sm-2 px-md-3 px-lg-4 px-2 container container-fluid"}
                     style={{ maxWidth: MAX_MAIN_CONTENT_WIDTH}}>
                    <h1 className={"mt-3"}>Contact Us</h1>
                    <hr className={"shadow"}/>
                </div>
                <motion.div variants={SLIDE_FROM_RIGHT}
                            className={"mt-sm-3 px-2 px-sm-3 px-md-4 px-lg-5 px-2 container container-fluid"}
                            style={{ maxWidth: MAX_MAIN_CONTENT_WIDTH}} ref={ref}>
                    <iframe ref={ref}
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3509.4254264855626!2d77.33707361744385!3d28.406417300000015!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cdd433c8ee3b1%3A0xa21f2c638693e8c4!2sVardaan%20center%20for%20special%20children!5e0!3m2!1sen!2sin!4v1674317950869!5m2!1sen!2sin"
                        style={{border: 0, width: "100%", height: "50vw", maxHeight: "45vh"}} allowFullScreen="" loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade" title={"Map"}></iframe>
                </motion.div>
                <div className={"mt-sm-2 px-1 px-sm-2 px-md-3 px-lg-4 px-2 container container-fluid"}
                     style={{ maxWidth: MAX_MAIN_CONTENT_WIDTH}}>
                    <hr className={"shadow"}/>
                </div>
                <motion.div variants={SLIDE_FROM_BELOW}
                            className={"mt-sm-3 px-2 px-sm-3 px-md-4 px-lg-5 px-2 container container-fluid"}
                            style={{ maxWidth: MAX_MAIN_CONTENT_WIDTH}}>
                    <Stack direction={"horizontal"} className={"w-100 align-items-center justify-content-around flex-wrap"} gap={2}>
                        <Button href={"mailto:Khushiishicharitabletrust@gmail.com"}
                                variant={"light"} className={"shadow-sm d-flex flex-column align-items-center justify-content-center"}>
                            <Envelope style={{width: 80, height: 64 * dimensions.height/1080}} className={"mb-1 p-1"}/>
                            <h6>Email</h6>
                        </Button>
                        <Button target={"_blank"}
                            href={"https://wa.me/+919871404550?text=I%20have%20inquiry%20about%20vardaan%20center%20for%20special%20children"}
                            variant={"light"} className={"shadow-sm d-flex flex-column align-items-center justify-content-center"}>
                            <Whatsapp style={{width: 80, height: 64 * dimensions.height/1080}} className={"mb-1 p-2"}/>
                            <h6>Whatsapp</h6>
                        </Button>
                        <Button href={"tel:+919871404550"}
                            variant={"light"} className={"shadow-sm d-flex flex-column align-items-center justify-content-center"}>
                            <Telephone style={{width: 80, height: 64 * dimensions.height/1080}} className={"mb-1 p-2"}/>
                            <h6>Call</h6>
                        </Button>
                        <Button target={"_blank"}
                                href={"https://maps.google.com/maps/dir//Vardaan+center+for+special+children+Flat+no.+505,+OMAXE+HEIGHTS+Mporis+Greater,+Sector+86,+Faridabad,+Haryana+121002/@28.4064173,77.3392623,0z/data=!4m5!4m4!1m0!1m2!1m1!1s0x390cdd433c8ee3b1:0xa21f2c638693e8c4"}
                            variant={"light"} className={"shadow-sm d-flex flex-column align-items-center justify-content-center"}>
                            <GlobeCentralSouthAsia style={{width: 80, height: 64 * dimensions.height/1080}} className={"mb-1 p-2"}/>
                            <h6>Visit</h6>
                        </Button>
                    </Stack>
                </motion.div>
                <div className={"mt-sm-3 px-1 px-sm-2 px-md-3 px-lg-4 px-2 container container-fluid"}
                     style={{ maxWidth: MAX_MAIN_CONTENT_WIDTH}}>
                    <hr className={"shadow"}/>
                </div>
            </motion.div>
        </Stack>

    );
};

export default Index;