import React from 'react';
import {useScroll, useSpring} from "framer-motion";
import {motion} from "framer-motion";

const Index = () => {
    const { scrollYProgress } = useScroll();
    const scaleX = useSpring(scrollYProgress, {
        stiffness: 100,
        damping: 10,
        restDelta: 0.001
    });

    scrollYProgress.onChange((latest) => {
        if (latest === 0) {
            window.location = "#";
        }
    })

    return (
        <>
            <motion.div
                style={{
                    height: 6,
                    width: "100%",
                    position: "sticky",
                    bottom: 0,
                    borderRadius: 0,
                    background: "rgba(105, 105, 105, 0.16)",
                    overflow: "hidden",
                }}>
                <motion.div variant="danger"
                            style={{
                                width: "100%",
                                scaleX: scaleX,
                                backgroundImage: "linear-gradient(45deg, #ff008e 0%, #ffcd1e 100%)",
                                height: 6,

                            }}
                />
            </motion.div>
        </>
    )
};

export default Index;