import Main from "./pages/Main";
import ScrollBasedProgress from "./components/ScrollBasedProgress";

function App() {
    return (
        <>
            <Main/>
            <ScrollBasedProgress/>
        </>
    );
}

export default App;
