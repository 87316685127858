export const MAX_MAIN_CONTENT_WIDTH = 1300;
export const MIN_MAIN_CONTENT_HEIGHT = 500;
export const MAX_NAVBAR_HEIGHT = 104
export const MIN_NAVBAR_HEIGHT = 64
export const MAX_NAVBAR_WIDTH = MAX_MAIN_CONTENT_WIDTH;
export const MIN_CAROUSEL_HEIGHT = 400;
export const MAX_CAROUSEL_IMAGE_HEIGHT = 800;
export const MAX_CAROUSEL_IMAGE_WIDTH = MAX_NAVBAR_WIDTH;
export const CAROUSEL_INTERVAL = 5000;

export const SLIDE_FROM_RIGHT = {
    offscreen: {
        x: "100%",
    },
    onscreen: {
        x: 0,
        transition: {
            type: "spring",
            bounce: 0.2,
            duration: 0.8,
            overflow: "hidden"
        }
    }
};

export const SLIDE_FROM_LEFT = {
    offscreen: {
        x: "-100%",
    },
    onscreen: {
        x: 0,
        transition: {
            type: "spring",
            bounce: 0.2,
            duration: 0.8,
            overflow: "hidden"
        }
    }
};

export const SLIDE_FROM_BELOW = {
    offscreen: {
        y: 300,
    },
    onscreen: {
        y: 0,
        transition: {
            type: "spring",
            bounce: 0.2,
            duration: 0.8,
            overflow: "hidden"
        }
    }
};
