import React, {useEffect, useState} from 'react';
import {Container, Nav, Navbar, Image} from "react-bootstrap";
import {useScroll} from "framer-motion"
import {MAX_NAVBAR_HEIGHT, MAX_NAVBAR_WIDTH, MIN_NAVBAR_HEIGHT} from "../../constants";
import Logo from "../../resources/logo.jpeg"

const Index = () => {
    const [location, setLocation] = useState(window.location.toString().split("#")[1] || "");
    const { scrollY } = useScroll()
    const [height, setHeight] = useState(0)

    useEffect(() => {
        setHeight(() => Math.max(MAX_NAVBAR_HEIGHT - scrollY.get()/1.62, MIN_NAVBAR_HEIGHT))
        return scrollY.onChange((latest) => {
            setHeight(Math.max(MAX_NAVBAR_HEIGHT - latest/1.62, MIN_NAVBAR_HEIGHT))
            setLocation(latest === 0 ? "" : window.location.toString().split("#")[1]);
        }) // eslint-disable-next-line
    }, [])

    return (
        <>
            <header className={"sticky-top"}>
                <Navbar expand="md" className={"shadow position-absolute w-100"}
                        style={{background: "linear-gradient(45deg, rgba(255, 221, 100, 0.933), rgba(251, 176, 152, 0.933))",
                            backdropFilter: "blur(4px)", minHeight: height}}>
                    <Container fluid style={{maxWidth: MAX_NAVBAR_WIDTH}}>
                        <Navbar.Brand href="#" className={"d-flex align-items-end"}
                                      style={{maxWidth: "calc(100% - 72px)"}}>
                            <Image src={Logo} width={MIN_NAVBAR_HEIGHT + 0.5 * (height-MIN_NAVBAR_HEIGHT)}
                                   className={"me-3"}/>
                            <span style={{fontSize: 24 + 0.1 * (height-MIN_NAVBAR_HEIGHT),
                                textOverflow: "ellipsis", overflow: "hidden", fontFamily: "StyleScript"}}>
                                Vardaan Center For Special Children
                            </span>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="navbarScroll"/>
                        <Navbar.Collapse id="navbarScroll"
                                         style={{fontSize: 16 + 0.05 * (height-MIN_NAVBAR_HEIGHT)}}>
                            <Nav
                                className="ms-auto my-2 my-md-0 d-flex align-items-md-center"
                                navbarScroll>
                                {/*<NavDropdown title="About Us" id="navbarScrollingDropdown">*/}
                                {/*    <NavDropdown.Item href="#action3">Action</NavDropdown.Item>*/}
                                {/*    <NavDropdown.Item href="#action4">*/}
                                {/*        Another action*/}
                                {/*    </NavDropdown.Item>*/}
                                {/*    <NavDropdown.Divider />*/}
                                {/*    <NavDropdown.Item href="#action5">*/}
                                {/*        Something else here*/}
                                {/*    </NavDropdown.Item>*/}
                                {/*</NavDropdown>*/}
                                <Nav.Link href="#activities" active={location === "activities"}>Activities</Nav.Link>
                                <Nav.Link href="#about-us" active={location === "about-us"}>About Us</Nav.Link>
                                <Nav.Link href="#contact-us" active={location === "contact-us"}>Contact Us</Nav.Link>
                            </Nav>
                            {/*<Form>*/}
                            {/*    <Form.Control*/}
                            {/*        type="search"*/}
                            {/*        placeholder="Search"*/}
                            {/*        className="me-2"*/}
                            {/*        aria-label="Search"*/}
                            {/*    />*/}
                            {/*</Form>*/}
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </header>
        </>

    );
};

export default Index;