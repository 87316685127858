import React, {useEffect} from 'react';
import CustomNavbar from "../../components/CustomNavbar";
import {Stack} from "react-bootstrap";
import MainImageDisplayCarousel from "../../components/MainImageDisplayCarousel";
import FullHeightContainer from "../../components/FullHeightContainer";
import AboutUs from "../../components/AboutUs";
import ContactUs from "../../components/ContactUs";
import Activities from "../../components/Activities";
import useWindowDimensions from "../../utils/hooks/useWindowDimensions";

const Index = () => {
    const windowDimensions = useWindowDimensions()

    useEffect(() => {
        let tokens = window.location.toString().split("#");
        if (tokens.length === 2) {
            try {
                const anchor = document.querySelector(`#${tokens[1]}`);
                anchor.scrollIntoView({ behavior: 'smooth', block: 'start' })
            } catch (e) {
                window.location = "";
            }
        }
        /* eslint-disable react-hooks/exhaustive-deps */
    }, [])

    return (
        <>
            <Stack className={windowDimensions.height > 800 && "mb-5"}>
                <CustomNavbar/>
                <FullHeightContainer isFirst={true}><MainImageDisplayCarousel/></FullHeightContainer>
                <FullHeightContainer><Activities/></FullHeightContainer>
                <FullHeightContainer><AboutUs/></FullHeightContainer>
                <FullHeightContainer><ContactUs/></FullHeightContainer>
            </Stack>
        </>
    );
};

export default Index;