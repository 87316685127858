import React from 'react';
import {Carousel, Image, Stack} from "react-bootstrap";
import Image1 from "../../resources/carouselImages/img1.jpeg";
import Image2 from "../../resources/carouselImages/img2.jpeg";
import Image3 from "../../resources/carouselImages/img3.jpeg";
import Image4 from "../../resources/carouselImages/img4.jpeg";
import Image5 from "../../resources/carouselImages/img5.jpeg";
import Image6 from "../../resources/carouselImages/img6.jpeg";
import Image7 from "../../resources/carouselImages/img7.jpeg";
import Image8 from "../../resources/carouselImages/img8.jpeg";
import Image9 from "../../resources/carouselImages/img9.jpeg";
import Image10 from "../../resources/carouselImages/img10.jpeg";
import Image11 from "../../resources/carouselImages/img11.jpeg";
import Image12 from "../../resources/carouselImages/img12.jpeg";
import Image13 from "../../resources/carouselImages/img13.jpeg";
import Image14 from "../../resources/carouselImages/img14.jpeg";
import Image15 from "../../resources/carouselImages/img15.jpeg";
import {
    CAROUSEL_INTERVAL,
    MAX_CAROUSEL_IMAGE_HEIGHT,
    MAX_NAVBAR_HEIGHT,
    MIN_MAIN_CONTENT_HEIGHT, MIN_NAVBAR_HEIGHT
} from "../../constants";

const Index = () => {
    const images = [Image1, Image2, Image3, Image4, Image5, Image6, Image7, Image8,
        Image9, Image10, Image11, Image12, Image13, Image14, Image15]
    const slideTitles = ["Vardaan Center For Special Children",
        "Vardaan Center For Special Children",
        "Vardaan Center For Special Children",
        "Vardaan Center For Special Children",
        "Individual Education Program",
        "Individual Education Program",
        "Individual Education Program",
        "Individual Education Program",
        "Occupational Therapy",
        "Occupational Therapy",
        "Occupational Therapy",
        "Occupational Therapy",
        "Contact Us",
        "Contact Us",
        "Contact Us"]
    const slideTexts = [
        "We provide special education to children with learning disabilities.",
        "We provide special education to children with learning disabilities.",
        "We provide special education to children with learning disabilities.",
        "We provide special education to children with learning disabilities.",
        "We provide individual sessions based on Individualized Education Program (IEP).",
        "We provide individual sessions based on Individualized Education Program (IEP).",
        "We provide individual sessions based on Individualized Education Program (IEP).",
        "We provide individual sessions based on Individualized Education Program (IEP).",
        "We also provide Occupational therapy.",
        "We also provide Occupational therapy.",
        "We also provide Occupational therapy.",
        "We also provide Occupational therapy.",
        <p>Click on <a href={"#contact-us"}>Contact Us</a> for consultation.</p>,
        <p>Click on <a href={"#contact-us"}>Contact Us</a> for consultation.</p>,
        <p>Click on <a href={"#contact-us"}>Contact Us</a> for consultation.</p>
    ]

    return (
        <Carousel pause={false} interval={CAROUSEL_INTERVAL} fade>
            {
                images.map((image, index) => (
                    <Carousel.Item key={index}
                                   style={{height: `calc(100vh - ${MAX_NAVBAR_HEIGHT}px)`,
                                       minHeight: MIN_MAIN_CONTENT_HEIGHT - (MAX_NAVBAR_HEIGHT-MIN_NAVBAR_HEIGHT),
                                       backgroundImage: `url(${image})`,
                                       backgroundSize: "cover",
                                       backgroundPosition: "center",
                                   }}>
                        <Stack className={"w-100 d-flex align-items-center justify-content-center h-100 bg-filter"}
                             style={{ backgroundColor: "rgba(100, 100, 100, 0.4)"}}>
                            <Image
                                className={"shadow-lg"}
                                src={image}
                                style={{
                                    maxWidth: "100%",
                                    maxHeight: MAX_CAROUSEL_IMAGE_HEIGHT,
                                    objectFit: "contain",
                                    objectPosition: "center",
                                }}
                             alt={image}/>
                            <Carousel.Caption style={{textShadow: "1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000"}}>
                                <h3>{slideTitles[index]}</h3>
                                {slideTexts[index]}
                            </Carousel.Caption>
                        </Stack>
                    </Carousel.Item>
                ))
            }
        </Carousel>
    );
};

export default Index;